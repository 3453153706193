<template>
  <div>
    <el-row :gutter="15">
      <el-col
        :sm="24"
        :md="12"
        :lg="8"
        v-if="checkUsrPermissions('Invoice store')"
      >
        <el-button type="primary" @click="openServiceInvoiceModal"
          >Create new Service Invoice</el-button
        >
        <el-button
          type="warning"
          @click="$router.push('/create/package/invoice')"
          >Create new Package Invoice</el-button
        >
      </el-col>
      <!-- v-if="authUser.user_type == 'executive'" -->
      <el-col :sm="24" :md="6" :lg="3" class="" v-if="authUser.user_type == 'executive'">
        <el-select
          @change="getInvoices()"
          class="m-2"
          v-model="center_id"
          placeholder="Select Center"
          size="large"
          clearable
        >
          <el-option
            v-for="item in centers"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-col>

      <el-col :sm="24" :md="6" :lg="4" class="">
        <el-select
          @change="getInvoices()"
          class="m-2"
          v-model="membership_type_id"
          placeholder="Select Referral Type"
          size="large"
          clearable
        >
          <el-option
            v-for="item in memberTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-col>

      <el-col :sm="24" :md="6" :lg="4" class="">
        <el-select
          @change="getInvoices()"
          class="m-2"
          v-model="platform"
          placeholder="Registration Source"
          size="large"
          clearable
        >
          <el-option label="Patient Referral" value="Patient Referral" />
          <el-option label="App" value="register" />
          <el-option label="Employee Referral" value="Employee Referral" />
          <el-option label="Poster" value="Poster" />
          <el-option label="Doctor Referral" value="Doctor Referral" />
          <el-option label="Social" value="Social" />
          <el-option label="NGO" value="NGO" />
        </el-select>
      </el-col>

      <el-col :sm="12" :md="3" :lg="3" class="text-right">
        <el-button type="warning" size="large" @click="getInvoices" circle>
          <font-awesome-icon icon="fa-solid fa-rotate-right" />
        </el-button>
      </el-col>
    </el-row>
    <br />
    <el-dialog
      v-model="service_invoice_dailog"
      :title="`${
        this.is_preview ? 'Invoice Preview' : 'Generate Service Invoice'
      }`"
      class="appointment-status-modal"
      size="lg"
    >
      <div v-if="is_preview">
        <el-form
          :model="service_invoice"
          label-position="top"
          label-width="100px"
          ref="invoice"
        >
          <el-row :gutter="20">
            <el-col :sm="24" :md="8" :lg="8">
              <p class="mb-0 mt-0">Invoice Date</p>
              <h4 class="mt-3">{{ service_invoice.invoice_date }}</h4>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8">
              <p class="mb-0 mt-0">Patient Name</p>
              <h4 class="mt-3">
                {{ patientName(service_invoice.patient_id) }}
              </h4>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8">
              <p class="mb-0 mt-0">Service</p>
              <h4 class="mt-3">
                {{ serviceName(service_invoice.service_id) }}
              </h4>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8" v-if="service_invoice.no_of_foot">
              <p class="mb-0 mt-0">No of Foot?</p>
              <h4 class="mt-3">{{ service_invoice.no_of_foot }}</h4>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8">
              <p class="mb-0 mt-0">Service Type</p>
              <h4 class="mt-3">
                {{ getServiceType(service_invoice.service_type) }}
              </h4>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8" v-if="service_invoice.size">
              <p class="mb-0 mt-0">Brace Size?</p>
              <h4 class="mt-3">{{ service_invoice.size }}</h4>
            </el-col>
            <el-col
              :sm="24"
              :md="8"
              :lg="8"
              v-if="service_invoice.home_service_provided_by"
            >
              <p class="mb-0 mt-0">Service Provider</p>
              <h4 class="mt-3">
                {{ service_invoice.home_service_provided_by }}
              </h4>
            </el-col>

            <el-col :sm="24" :md="8" :lg="8">
              <p class="mb-0 mt-0">TP/FP Phase?</p>
              <h4 class="mt-3">
                {{ service_invoice.is_followup ? "Followup" : "Treatment" }}
              </h4>
            </el-col>
          </el-row>

          <el-row
            :gutter="20"
            v-if="service_invoice.service_id == 'Appointment'"
          >
            <el-col :sm="24" :md="24" :lg="24">
              <div v-if="service_invoice.appointment_id">
                <p class="mb-0 mt-0">Appointment</p>
                <h4 class="mt-3">
                  {{ appointmentById(service_invoice.appointment_id) }}
                </h4>
              </div>
            </el-col>
          </el-row>
          <br /><br />

          <table
            v-if="
              service_details.length &&
              service_invoice.service_id != 'Appointment'
            "
            v-loading="loading"
          >
            <tr class="text-center">
              <th>Service</th>
              <th>Quantity</th>
              <th v-if="service_invoice.service_id == 'Dental'">Discount(%)</th>
              <th>Price</th>
            </tr>
            <tr v-for="(item, index) in service_details" :key="index">
              <td>
                {{ item.name }}
                {{ item.description ? "- (" + item.description + ")" : "" }}
              </td>
              <td>{{ item.quantity ? item.quantity : 1 }}</td>
              <td v-if="service_invoice.service_id == 'Dental'">
                {{ item.discount }}
              </td>
              <td>
                {{ item.price }}
              </td>
            </tr>

            <tr>
              <td
                :colspan="service_invoice.service_id == 'Dental' ? 3 : 2"
                class="text-right"
              >
                Sub Total
              </td>
              <td>{{ subtotal }}</td>
            </tr>

            <tr>
              <td
                :colspan="service_invoice.service_id == 'Dental' ? 3 : 2"
                class="text-right"
              >
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <div v-if="service_invoice.membership_type_id">
                      <p class="mb-0 mt-0">Membership type</p>
                      <h4 class="mt-3">
                        {{
                          membershipTypeNameById(
                            service_invoice.membership_type_id
                          )
                        }}
                      </h4>
                    </div>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <div v-if="service_invoice.employee_id">
                      <p class="mb-0 mt-0">Employee</p>
                      <h4 class="mt-3">
                        {{ employeeNameById(service_invoice.employee_id) }}
                      </h4>
                    </div>
                  </el-col>
                </el-row>
                Discount(%)
                <p v-if="service_invoice.discount">
                  {{ service_invoice.discount_reason }}
                </p>
              </td>
              <td style="width: 150px; text-align: center">
                <p>{{ service_invoice.discount }}</p>
              </td>
            </tr>

            <tr v-if="welfare_details.discount_due">
              <td
                :colspan="service_invoice.service_id == 'Dental' ? 3 : 2"
                class="text-right"
              >
                Welfare discount
              </td>
              <td>{{ service_invoice.welfare_discount }}</td>
            </tr>

            <tr>
              <td
                :colspan="service_invoice.service_id == 'Dental' ? 3 : 2"
                class="text-right"
              >
                Tax
              </td>
              <td>0.00</td>
            </tr>

            <tr>
              <td
                :colspan="service_invoice.service_id == 'Dental' ? 3 : 2"
                class="text-right"
              >
                Total
              </td>
              <td>{{ total }}</td>
            </tr>
            <tr
              v-if="
                service_invoice.total_paid !== undefined &&
                service_invoice.product_type == 'Dental'
              "
            >
              <td
                :colspan="service_invoice.service_id == 'Dental' ? 3 : 2"
                class="text-right"
              >
                Paid Amount
              </td>
              <td>{{ service_invoice.total_paid }}</td>
            </tr>
            <tr
              v-if="
                service_invoice.total_paid !== undefined &&
                service_invoice.product_type == 'Dental'
              "
            >
              <td
                :colspan="service_invoice.service_id == 'Dental' ? 3 : 2"
                class="text-right"
              >
                Total Due
              </td>
              <td>{{ Math.round(total - service_invoice.total_paid, 2) }}</td>
            </tr>
          </table>

          <table
            v-if="
              service_invoice.service_id == 'Appointment' &&
              service_invoice.appointment_id
            "
            v-loading="loading"
          >
            <tr class="text-center">
              <th>Service</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
            <tr v-for="(item, index) in service_details" :key="index">
              <td>Appointment</td>
              <td>1</td>
              <td>
                {{ item.payable_amount }}
              </td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">Sub Total</td>
              <td>{{ subtotal }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <div v-if="service_invoice.membership_type_id">
                      <p class="mb-0 mt-0">Membership type</p>
                      <h4 class="mt-3">
                        {{
                          membershipTypeNameById(
                            service_invoice.membership_type_id
                          )
                        }}
                      </h4>
                    </div>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <div v-if="service_invoice.employee_id">
                      <p class="mb-0 mt-0">Employee</p>
                      <h4 class="mt-3">
                        {{ employeeNameById(service_invoice.employee_id) }}
                      </h4>
                    </div>
                  </el-col>
                </el-row>
                Discount(%)
                <p v-if="service_invoice.discount">
                  {{ service_invoice.discount_reason }}
                </p>
              </td>
              <td style="width: 150px; text-align: center">
                <p>{{ service_invoice.discount }}</p>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Tax</td>
              <td>0.00</td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Total</td>
              <td>{{ total }}</td>
            </tr>
          </table>
          <br />
          <div v-if="service_invoice.comment">
            <p class="mb-0 mt-0">Comment</p>
            <h4 class="mt-3">{{ service_invoice.comment }}</h4>
          </div>

          <div class="text-center">
            <el-button type="dark" @click="is_preview = false">
              Back
            </el-button>
            <el-button type="primary" @click="submitInvoice('invoice')">
              Confirm
            </el-button>
          </div>
        </el-form>
      </div>
      <div v-else>
        <el-form
          :model="service_invoice"
          label-position="top"
          label-width="100px"
          ref="invoice"
        >
          <el-row :gutter="20">
            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item
                label="Select Date"
                prop="invoice_date"
                :rules="[
                  {
                    required: true,
                    message: 'Please select invoice date',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-date-picker
                  class="w-100"
                  v-model="service_invoice.invoice_date"
                  type="date"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  @change="dateChanged()"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item
                label="Search Patient"
                prop="patient_id"
                :rules="[
                  {
                    required: true,
                    message: 'Please select phone number',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  v-model="service_invoice.patient_id"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="Please enter phone number"
                  :remote-method="searchGlobalPatient"
                  :loading="loading"
                  clearable
                  class="w-100"
                  required
                  @change="checkDoctorReferral()"
                >
                  <el-option
                    v-for="item in patients"
                    :key="item.id"
                    :label="item.fullname"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item
                label="Select Service"
                prop="service_id"
                :rules="[
                  {
                    required: true,
                    message: 'Please select service',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  @change="
                    service_invoice.no_of_foot = '';
                    priceCalculate();
                  "
                  class="w-100"
                  v-model="service_invoice.service_id"
                  clearable
                  filterable
                  placeholder="Select Service"
                  required
                >
                  <el-option
                    v-for="item in services"
                    :key="item.name"
                    :label="`${item.name} ${
                      item.size ? ' (Size - ' + item.size + ')' : ''
                    }`"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col
              :sm="24"
              :md="8"
              :lg="8"
              v-if="
                ['Brace', 'Ponseti (Plaster)', 'Ponseti (Tenotomy)', 'Ponseti (Fasciotomy)'].includes(
                  service_invoice.service_id
                )
              "
            >
              <el-form-item
                label="Select Foot"
                prop="no_of_foot"
                :rules="[
                  {
                    required: false,
                    message: 'Please select Foot',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  @change="priceCalculate"
                  class="w-100"
                  v-model="service_invoice.no_of_foot"
                  clearable
                  filterable
                  placeholder="Select No of Foot"
                  required
                >
                  <el-option label="1 Foot" :value="1" />
                  <el-option label="Both Feet" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item
                label="Select Service Type"
                prop="service_type"
                :rules="[
                  {
                    required: true,
                    message: 'Please select service type',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  @change="getServiceType(service_invoice.service_type)"
                  class="w-100"
                  v-model="service_invoice.service_type"
                  clearable
                  filterable
                  placeholder="Select Service Type"
                  required
                >
                  <el-option
                    v-for="item in serviceType"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col
              :sm="24"
              :md="8"
              :lg="8"
              v-if="service_invoice.service_id == 'Brace'"
            >
              <el-form-item
                label="Brace Size?"
                prop="size"
                :rules="[
                  {
                    required: true,
                    message: 'Please select Size',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  v-model="service_invoice.size"
                  placeholder="Brace Size"
                  clearable
                  class="w-100"
                >
                  <el-option
                    v-for="item in sizeOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col
              :sm="24"
              :md="8"
              :lg="8"
              v-if="service_invoice.service_id == 'Home Service'"
            >
              <el-form-item
                label="Home Service Provider"
                prop="home_service_provided_by"
                :rules="[
                  {
                    required: true,
                    message: 'Please select provider',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  v-model="service_invoice.home_service_provided_by"
                  placeholder="Service Provider"
                  clearable
                  class="w-100"
                >
                  <el-option
                    v-for="(item, key) in homeServiceProviders"
                    :key="key"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" :lg="8">
              <el-form-item
                label="TP/FP Phase?"
                prop="is_followup"
                :rules="[
                  {
                    required: true,
                    message: 'Please Select Phase',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                  v-model="service_invoice.is_followup"
                  placeholder="Select Phase"
                  clearable
                  class="w-100"
                >
                  <el-option
                    v-for="item in phaseType"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col
              :sm="24"
              :md="12"
              :lg="12"
              v-if="service_invoice.service_id == 'Dental'"
            >
              <el-form-item label="Select Doctor" prop="doctor_id">
                <el-select
                  class="w-100"
                  v-model="service_invoice.doctor_id"
                  clearable
                  filterable
                  placeholder="Select Doctor"
                  required
                >
                  <el-option
                    v-for="item in doctors"
                    :key="item.id"
                    :label="item.username"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row
            :gutter="20"
            v-if="service_invoice.service_id == 'Appointment'"
          >
            <el-col :sm="24" :md="24" :lg="24">
              <el-form-item label="Select appointment" prop="service_id">
                <el-select
                  class="w-100"
                  v-model="service_invoice.appointment_id"
                  clearable
                  filterable
                  placeholder="Select Appointment"
                  @change="selectAppointment"
                >
                  <el-option
                    v-for="item in appointments"
                    :key="item.name"
                    :label="`${item.schedule_date} (${item.schedule_started_at})`"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <br /><br />

          <table
            v-if="
              !subServices.length &&
              service_details.length &&
              service_invoice.service_id != 'Appointment'
            "
            v-loading="loading"
          >
            <tr class="text-center">
              <th>Service</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
            <tr v-for="(item, index) in service_details" :key="index">
              <td>
                <span v-if="service_invoice.service_id == 'Home Service'">
                  {{ item.name }}</span
                >
                <span v-else>
                  {{ item.name }} -
                  {{ item.description ? "(" + item.description + ")" : "-" }}
                </span>
              </td>
              <td>
                <el-input
                  v-if="service_invoice.service_id == 'Home Service'"
                  class="w-100 text-center"
                  v-model="item.quantity"
                  clearable
                  type="number"
                />
                <span v-else>1</span>
              </td>
              <td>
                <el-input
                  v-if="service_invoice.service_id == 'Home Service'"
                  class="w-100 text-center"
                  v-model="item.price"
                  clearable
                  type="number"
                />
                <span v-else>{{ item.price }}</span>
              </td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">Sub Total</td>
              <td>{{ subtotal }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="membership_type_id">
                      <el-select
                        @change="changeEmployeeType"
                        class="w-100"
                        v-model="service_invoice.membership_type_id"
                        clearable
                        filterable
                        placeholder="Select Membership type"
                      >
                        <el-option
                          v-for="item in memberTypes"
                          :key="item.id"
                          :label="item.name"
                          :value="item.name"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="employee_id">
                      <el-select
                        @change="checkMembershipType"
                        v-model="service_invoice.employee_id"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Please enter name or phone number"
                        :remote-method="getEmployees"
                        :loading="loading"
                        clearable
                        class="w-100"
                      >
                        <el-option
                          v-for="item in employees"
                          :key="item.id"
                          :label="item.fullname"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :sm="24" :md="6" :lg="6">
                      <el-button type="primary" @click="createNewUser()" v-if="service_invoice.membership_type_id == 'Doctor Referral' || service_invoice.membership_type_id == 'General Referral'">
                        Create New
                      </el-button> 
                    </el-col>

                  <el-col :sm="24" :md="18" :lg="18">
                    Discount(%)
                    <el-input
                      placeholder="Discount reason"
                      class="w-100"
                      :input-style="{ textAlign: 'center' }"
                      v-model="service_invoice.discount_reason"
                      clearable
                    />
                      </el-col>
                </el-row>
                
              </td>
              <td style="width: 150px; text-align: center">
                <el-input
                  class="w-100"
                  v-model="service_invoice.discount"
                  clearable
                  :input-style="{ textAlign: 'center' }"
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">
                <el-checkbox
                  v-model="is_walefire_discount"
                  @change="checkWelfareDiscount"
                  label="Welfare Discount"
                  name="type"
                />
              </td>
              <td>
                <el-input
                  v-if="welfare_details.expected_discount_amount"
                  placeholder="Welfare Discount Amount"
                  class="w-100"
                  :input-style="{ textAlign: 'center' }"
                  type="number"
                  v-model="service_invoice.welfare_discount"
                  :max="welfare_details.discount_due"
                  clearable
                />
                <span v-else>0.00</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Tax</td>
              <td>0.00</td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Total</td>
              <td>{{ total }}</td>
            </tr>
          </table>
          <table
            v-else-if="
              service_invoice.service_id == 'Appointment' &&
              service_invoice.appointment_id
            "
            v-loading="loading"
          >
            <tr class="text-center">
              <th>Service</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
            <tr v-for="(item, index) in service_details" :key="index">
              <td>Appointment</td>
              <td>1</td>
              <td>
                {{ item.payable_amount }}
              </td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">Sub Total</td>
              <td>{{ subtotal }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-right">
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="membership_type_id">
                      <el-select
                        @change="changeEmployeeType"
                        class="w-100"
                        v-model="service_invoice.membership_type_id"
                        clearable
                        filterable
                        placeholder="Select Membership type"
                      >
                        <el-option
                          v-for="item in memberTypes"
                          :key="item.id"
                          :label="item.name"
                          :value="item.name"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="employee_id">
                      <el-select
                        @change="checkMembershipType"
                        v-model="service_invoice.employee_id"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Please enter name or phone number"
                        :remote-method="getEmployees"
                        :loading="loading"
                        clearable
                        class="w-100"
                      >
                        <el-option
                          v-for="item in employees"
                          :key="item.id"
                          :label="item.fullname"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                Discount(%)
                <el-input
                  placeholder="Discount reason"
                  class="w-100"
                  v-model="service_invoice.discount_reason"
                  clearable
                />
              </td>
              <td style="width: 150px; text-align: center">
                <el-input
                  class="w-100 text-center"
                  v-model="service_invoice.discount"
                  :disabled="service_invoice.employee_id ? true : false"
                  clearable
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Tax</td>
              <td>0.00</td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Total</td>
              <td>{{ total }}</td>
            </tr>
          </table>

          <!-- For Dynamic services like - dental -->
          <table
            v-else-if="
              subServices.length &&
              service_details.length &&
              service_invoice.service_id != 'Appointment'
            "
            v-loading="loading"
          >
            <tr class="text-center">
              <th>Service</th>
              <th>Quantity</th>
              <th>Discount(%)</th>
              <th>Price</th>
            </tr>
            <tr v-for="(item, index) in service_details" :key="index">
              <td>
                <el-select
                  class="w-100"
                  v-model="item.id"
                  clearable
                  filterable
                  placeholder="Select Service"
                  @change="setServicePrice(item)"
                  required
                >
                  <el-option
                    v-for="val in subServices"
                    :key="val.id"
                    :value="val.id"
                    :label="`${val.name}`"
                  />
                </el-select>
              </td>
              <td>
                <el-input
                  type="number"
                  input-style="text-align: center"
                  v-model="item.quantity"
                  :min="1"
                  :max="item.available_quantity ? item.available_quantity : 10"
                  @input="
                    setServicePrice(item);
                    checkItemValue(0, 50, item, 'quantity');
                  "
                  required
                />
                <small v-if="item.available_quantity" style="font-weight: bold"
                  >(available: {{ item.available_quantity }})</small
                >
              </td>
              <td>
                <el-input
                  type="number"
                  input-style="text-align: center"
                  v-model="item.discount"
                  :min="0"
                  :max="100"
                  @input="
                    setServicePrice(item);
                    checkItemValue(0, 100, item, 'discount');
                  "
                />
              </td>
              <td>
                <el-input
                  type="number"
                  input-style="text-align: center"
                  v-if="item.min_price"
                  v-model="item.price"
                  :min="item.min_price"
                  :step="50"
                  :max="item.max_price"
                  @input="
                    updateUnitPrice(item);
                    checkItemValue(
                      item.min_price,
                      item.max_price,
                      item,
                      'price'
                    );
                  "
                  required
                />

                <small v-if="item.min_price"
                  >Price ({{ item.min_price + " - " + item.max_price }})</small
                >
                <span v-else>{{ item.price }}</span>
              </td>
            </tr>

            <tr>
              <td style="text-align: left">
                <el-button type="success" size="small" @click="addNewRow()">
                  + Add New Item
                </el-button>
                <el-button
                  v-if="service_details.length > 1"
                  type="danger"
                  size="small"
                  @click="removeLastRow()"
                >
                  - Remove Last Item
                </el-button>
              </td>
              <td colspan="2" class="text-right">Sub Total</td>
              <td>{{ subtotal }}</td>
            </tr>

            <tr>
              <td colspan="3" class="text-right">
                <el-row :gutter="20">
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="membership_type_id">
                      <el-select
                        @change="changeEmployeeType"
                        class="w-100"
                        v-model="service_invoice.membership_type_id"
                        clearable
                        filterable
                        placeholder="Select Membership type"
                      >
                        <el-option
                          v-for="item in memberTypes"
                          :key="item.id"
                          :label="item.name"
                          :value="item.name"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item prop="employee_id">
                      <el-select
                        @change="checkMembershipType"
                        v-model="service_invoice.employee_id"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Please enter name or phone number"
                        :remote-method="getEmployees"
                        :loading="loading"
                        clearable
                        class="w-100"
                      >
                        <el-option
                          v-for="item in employees"
                          :key="item.id"
                          :label="item.fullname"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                Discount(%)
                <el-input
                  placeholder="Discount reason"
                  class="w-100"
                  input-style="text-align: center"
                  v-model="service_invoice.discount_reason"
                  clearable
                />
              </td>
              <td style="width: 150px; text-align: center">
                <el-input
                  class="w-100"
                  input-style="text-align: center"
                  v-model="service_invoice.discount"
                  clearable
                  type="number"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3" class="text-right">
                <el-checkbox
                  v-model="is_walefire_discount"
                  @change="checkWelfareDiscount"
                  label="Welfare Discount"
                  name="type"
                />
              </td>
              <td>
                <el-input
                  v-if="welfare_details.expected_discount_amount"
                  placeholder="Welfare Discount Amount"
                  class="w-100"
                  :input-style="{ textAlign: 'center' }"
                  type="number"
                  v-model="service_invoice.welfare_discount"
                  :max="welfare_details.discount_due"
                  clearable
                />
                <span v-else>0.00</span>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="text-right">Tax</td>
              <td>0.00</td>
            </tr>
            <tr>
              <td colspan="3" class="text-right">Total</td>
              <td>{{ total }}</td>
            </tr>
            <tr v-if="service_invoice.service_id == 'Dental'">
              <td colspan="3" class="text-right">Paid Amount</td>
              <td>
                <el-input
                  type="number"
                  v-model="service_invoice.total_paid"
                  input-style="text-align: center"
                  :min="0"
                  :step="50"
                  :max="total"
                  @input="
                    checkItemValue(0, total, service_invoice, 'total_paid')
                  "
                />
                <!-- <el-input
                    class="w-100"
                    v-model="service_invoice.total_paid"
                    clearable
                    input-style="text-align: center"
                    type="number"
                /> -->
              </td>
            </tr>
            <tr v-if="service_invoice.service_id == 'Dental'">
              <td colspan="3" class="text-right">Total Due</td>
              <td>{{ total - service_invoice.total_paid }}</td>
            </tr>
          </table>
          <br />

          <el-form-item label="Comment" prop="comment">
            <el-input v-model="service_invoice.comment" type="textarea" />
          </el-form-item>
          <div class="text-center">
            <el-button type="danger" @click="service_invoice_dailog = false">
              Cancel
            </el-button>
            <el-button type="primary" @click="previewInvoice('invoice')">
              Preview
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-row :gutter="20" align="middle" justify="center">
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">Patient number</p>
        <el-input v-model="phone_number" type="number" size="large"></el-input>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">Start date</p>
        <el-date-picker
          v-model="start_date"
          type="date"
          class="w-100"
          placeholder="Pick a day"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <p class="demonstration">End date</p>
        <el-date-picker
          v-model="end_date"
          type="date"
          class="w-100"
          placeholder="Pick a day"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
        <p>Date sorting</p>
        <el-select
          v-model="orderBy"
          class="m-2"
          placeholder="Select"
          size="large"
        >
          <el-option label="ascending" value="date_asc" />
          <el-option label="Descending" value="date_desc" />
        </el-select>
      </el-col>
      <el-col :xs="24" :sm="4" :md="2" :lg="2" :xl="2">
        <div class="mt-40">
          <el-checkbox v-model="is_zakat" label="Is Zakat ?" size="large" />
        </div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4" class="mt-40">
        <el-button type="primary" size="large" @click="getInvoices" round
          >Filter
        </el-button>
        <el-button type="danger" size="large" @click="clear" round
          >Clear
        </el-button>
      </el-col>
      <el-col :xs="4" :sm="4" :md="3" :lg="3" :xl="3" class="mt-40 text-right">
        <el-button
          v-if="checkUsrPermissions('Invoice list export')"
          type="success"
          size="large"
          @click="exportToExcel"
          round
          >Export
        </el-button>
      </el-col>
    </el-row>
    <br />
    <el-table :data="items" border v-loading="loading">
      <el-table-column prop="patient" label="Patient" width="180">
        <template #default="scope">
          {{ scope.row.patient ? scope.row.patient.fullname : "" }} <br />
          <span
            v-if="
              authUser.user_type == 'agent-pharmacy' ||
              authUser.user_type == 'cashier' ||
              authUser.user_type == 'crm-manager'
            "
            >{{ scope.row.patient ? scope.row.patient.phone_number : "" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="platform" label="Reg. Source" width="145">
        <template #default="scope">
          {{  (scope.row.patient && scope.row.patient.platform == 'register')?'App':scope.row.patient.platform }}
        </template>
      </el-table-column>
      <el-table-column label="Reg. Date" width="145">
        <template #default="scope">
          {{  (scope.row.patient)?scope.row.patient.date:'' }}
        </template>
      </el-table-column>
      <el-table-column label="Ref. Type" prop="membership_type" width="140">
      </el-table-column>
      <el-table-column label="Ref. By" width="170">
        <template #default="scope">
          {{ scope.row.referral ? scope.row.referral.fullname : "" }} <br />
          <span
            >{{ scope.row.referral ? scope.row.referral.phone_number : "" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="Invoice No" width="160" header-align="center">
        <template #default="scope">
          <div class="text-center">
            {{ scope.row.uid }}
            <span v-if="scope.row.total_installments">Installments:</span>
            <router-link
              :to="`/invoices/${scope.row.uid}/installments`"
              v-if="scope.row.total_installments"
            >
              <el-tag class="mx-1" type="warning" effect="dark">
                {{ scope.row.total_installments }}
              </el-tag>
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="product_type"
        width="155px"
        label="Service type"
        header-align="center"
      >
        <template #default="scope">
          <div class="text-center">
            <el-tag
              v-if="scope.row.product_type == 'service'"
              class="mx-1"
              type="warning"
              effect="dark"
            >
              {{ scope.row.product_type }}
            </el-tag>
            <el-tag
              v-else-if="scope.row.product_type == 'therapy'"
              type="primary"
              class="mx-1"
              effect="dark"
            >
              {{ scope.row.product_type }}
            </el-tag>
            <el-tag v-else type="info" class="mx-1" effect="dark">
              {{ scope.row.product_type }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="subtotal" label="Sub total" width="85" />
      <el-table-column
        prop="discount_percentage"
        label="Discount(%)"
        width="110"
      />
      <el-table-column
        prop="discount_reason"
        label="Discount Reasons"
        width="140"
      />
      <el-table-column
        prop="welfare_discount"
        label="Welfare Discount"
        width="140"
      />
      <el-table-column prop="total" label="Total" />
      <el-table-column prop="total_paid" label="Paid Amount" width="110" />
      <el-table-column prop="total_due" label="Due" />
      <el-table-column prop="status" label="Status" width="100">
        <template #default="scope">
          <el-tag class="ml-2" type="success" v-if="scope.row.status == 'paid'"
            >{{ scope.row.status }}
          </el-tag>
          <el-tag class="ml-2" type="danger" v-else
            >{{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="invoice_date"
        label="Invoice Date"
        width="115"
        sortable
        @click="getInvoices()"
      />
      <el-table-column prop="created_at" label="Generated At" width="115" />
      <el-table-column prop="actions" label="Actions" width="80">
        <template #default="scope" v-if="checkUsrPermissions('Invoice show')">
          <el-dropdown>
            <span class="el-dropdown-link">
              <el-button type="info" circle>
                <el-icon><More /></el-icon>
              </el-button>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="$router.push(`/invoice/${scope.row.uid}`)"
                >
                  Invoice Details
                </el-dropdown-item>

                <!-- v-if="checkUsrPermissions('Invoice: add New Service')" -->
                <el-dropdown-item
                  @click="showServiceDialog(scope.row)"
                  v-if="checkUsrPermissions('Invoice: add New Service')"
                >
                  Add New Service
                </el-dropdown-item>

                <el-dropdown-item
                  @click="$router.push(`/invoices/${scope.row.uid}/services`)"
                  v-if="checkUsrPermissions('Invoice: Get Service History')"
                >
                  Service History
                </el-dropdown-item>

                <el-dropdown-item
                  @click="showInstallmentDialog(scope.row)"
                  v-if="
                    scope.row.status == 'partial' &&
                    checkUsrPermissions('Invoice: add installment')
                  "
                >
                  Installment payment
                </el-dropdown-item>

                <el-dropdown-item
                  @click="
                    $router.push(`/invoices/${scope.row.uid}/installments`)
                  "
                  v-if="
                    (scope.row.product_type == 'Dental' ||
                      scope.row.orko_therapy_package_id) &&
                    checkUsrPermissions('Invoice: add installment')
                  "
                >
                  Installment History
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      :page-size="per_page"
      layout="total, prev, pager, next"
      :total="total_count"
      @current-change="getInvoices"
    />

    <el-dialog
      v-model="installmentDialog"
      title="Installment Payment"
      class="appointment-status-modal"
      size="lg"
    >
      <el-form
        :model="installment"
        label-position="top"
        label-width="100px"
        ref="installment"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            v-if="
              installment.total_session &&
              installment.invoice_no_of_session_payment
            "
          >
            <el-form-item
              label="No of Sessions Patient wants to pay?"
              prop="no_of_session_payment"
              :rules="[
                {
                  required: true,
                  message: 'Please No of Sessions Patient wants to pay',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                @change="calculatePaidAmount"
                class="w-100"
                v-model="installment.no_of_session_payment"
                clearable
                filterable
                placeholder="Select No of Session"
                required
              >
                <el-option
                  v-for="sNo in installment.total_session -
                  installment.invoice_no_of_session_payment"
                  :key="sNo"
                  :label="sNo"
                  :value="sNo"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <p class="demonstration">Installment Date</p>
            <div class="block w-100">
              <el-date-picker
                class="w-100"
                v-model="installment.date"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                required
              />
            </div>
          </el-col>
          <el-col
            :xs="12"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
            v-if="!installment.invoice_no_of_session_payment"
          >
            <p class="demonstration">Installment Amount</p>
            <el-input
              type="number"
              v-model="installment.total_paid"
              :min="0"
              :step="50"
              :max="installment.total_due"
              @input="
                insCheckMinMaxVal(
                  0,
                  installment.total_due,
                  installment.total_paid
                )
              "
              style="width: 250px"
            />
            <br />
            <strong>DUE: {{ remainingDue }} Tk.</strong>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-else>
            <p class="demonstration" style="color: red">Installment Amount</p>
            <strong style="color: red; font-size: 22px; margin-left: 10px"
              >{{ installment.total_paid }} Tk.</strong
            >
            &nbsp;&nbsp; (<strong>DUE: {{ remainingDue }} Tk.</strong>)
          </el-col>
          <br />
        </el-row>
        <br />

        <div>
          <br />

          <div v-if="installment.invoice_no_of_session_payment">
            <h2 class="text-center">Session Booking</h2>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="new_patient_section d-flex">
                  <div class="w-100">
                    <el-form-item
                      label="Select Therapy Assistant"
                      prop="doctor_id"
                      :rules="[
                        {
                          required: true,
                          message: 'Please input doctor',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        class="w-100"
                        v-model="installment.doctor_id"
                        filterable
                        clearable
                        placeholder="Select Therapy Assistant"
                      >
                        <el-option
                          v-for="item in assistants"
                          :key="item.id"
                          :label="item.username"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <el-form-item label="Appointment type" prop="service_type">
                  <el-radio-group v-model="installment.service_type">
                    <el-radio label="visit" />
                    <el-radio label="virtual" />
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              class="mb-5"
              v-if="installment.doctor_id"
              v-for="(appointment, index) in installment.appointments"
              :key="index"
            >
              <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="6" class="row-gap">
                <div class="d-flex justify-content-between">
                  <div class="w-100">
                    <el-form-item
                      :prop="'appointments.' + index + '.schedule_date'"
                      :rules="{
                        required: true,
                        message: 'Schedule date and Chamber slot required',
                        trigger: 'blur',
                      }"
                    >
                      <el-date-picker
                        class="app_date_picker w-100"
                        popper-class="my-datepicker"
                        v-model="appointment.schedule_date"
                        type="date"
                        placeholder="Pick schedule date"
                        size="default"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        @change="clickDate(appointment.schedule_date, index)"
                      />
                    </el-form-item>
                  </div>
                </div>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="16"
                :lg="18"
                :xl="18"
                class="row-gap"
              >
                <div
                  v-if="
                    appointment.chambers.length && appointment.schedule_date
                  "
                >
                  <ChamberWithSlots
                    @workPlaceId="workPlaceId"
                    :app_index="index"
                    :chambers="appointment.chambers"
                    v-model="appointment.schedule_started_at"
                    @slotNull="formDataNull"
                  />
                </div>
              </el-col>
            </el-row>
            <br />
          </div>
        </div>

        <div class="text-center">
          <el-button type="primary" @click="submitInstallment()">
            Confirm
          </el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      v-model="invServiceDialog"
      title="Add New Service"
      class="appointment-status-modal"
      size="lg"
    >
      <el-form
        :model="invService"
        label-position="top"
        label-width="100px"
        ref="invService"
      >
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Product Type">
              <el-input v-model="invService.product_type" disabled />
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              label="Service Date"
              prop="service_date"
              :rules="[
                {
                  required: true,
                  message: 'Please select date',
                  trigger: 'blur',
                },
              ]"
            >
              <el-date-picker
                class="w-100"
                v-model="invService.service_date"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                required
              />
            </el-form-item>
          </el-col>

          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              label="Quantity"
              prop="quantity"
              :rules="[
                {
                  required: true,
                  message: 'Please select quantity',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="number"
                v-model="invService.quantity"
                :min="1"
                :max="10"
                required
              />
            </el-form-item>
          </el-col>

          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              label="Size"
              prop="size"
              :rules="[
                {
                  required: false,
                  message: 'Please select Size',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="invService.size"
                placeholder="Size"
                clearable
                class="w-100"
              >
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Remarks">
              <el-input v-model="invService.remarks" />
            </el-form-item>
          </el-col>
          <br />
        </el-row>
        <br />

        <div>
          <br />
        </div>

        <div class="text-center">
          <el-button type="primary" @click="submitSerInvoice()">
            Confirm
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { CenterMixins } from "@/mixins/CenterMixins";
import { ElMessage } from "element-plus";
import ChamberWithSlots from "../../components/ChamberWithSlots";

export default {
  name: "InvoiceList",
  mixins: [CenterMixins],
  components: {
    ChamberWithSlots,
  },
  data() {
    return {
      items: [],
      total_count: 0,
      loading: false,
      currentPage: 0,
      per_page: 20,
      start_date: null,
      end_date: null,
      service_invoice_dailog: false,
      invServiceDialog: false,
      homeServiceProviders: [
        { name: "Physio and Physio Assistant", price: 0 },
        { name: "Physio", price: 0 },
        { name: "Physio Assistant", price: 0 },
      ],
      service_invoice: {
        patient_id: "",
        service_id: "",
        service_type: "",
        appointment_id: "",
        discount: 0,
        comment: "",
        discount_reason: "",
        qty: 1,
        membership_type_id: "",
        invoice_date: this.todayDate(),
        employee_id: "",
        center_code: "",
        total_paid: 0,
        doctor_id: "",
        welfare_application_id: "",
        home_service_provided_by: "",
        size: "",
        is_followup: "",
      },
      patients: [],
      services: [],
      serviceType: [
        { label: "ZCF", value: "ZCF" },
        { label: "PHT", value: "PHT" },
        { label: "CP", value: "CP" },
        { label: "Autism", value: "Autism" },
        { label: "Rickets/Bow Leg", value: "Rickets/Bow Leg" },
        { label: "Dental", value: "Dental" },
      ],
      phaseType: [
        { label: "Treatment", value: 0 },
        { label: "Followup", value: 1 },
      ],
      service_details: [],
      invoice: {},
      appointments: [],
      memberTypes: [],
      employees: [],
      search_employee: "",
      phone_number: "",
      membership_type_id: "",
      platform: "",
      is_preview: false,
      is_zakat: 0,
      orderBy: "date_desc",
      subServices: [],
      installmentDialog: false,
      installment: {},
      doctors: [],
      is_walefire_discount: "",
      welfare_details: {},
      sizeOptions: [
        { id: "S", name: "S" },
        { id: "M", name: "M" },
        { id: "L", name: "L" },
        { id: "XL", name: "XL" },
        { id: "XXL", name: "XXL" },
        { id: "5", name: "5" },
        { id: "6", name: "6" },
        { id: "7", name: "7" },
        { id: "8", name: "8" },
        { id: "9", name: "9" },
        { id: "10", name: "10" },
        { id: "11", name: "11" },
        { id: "12", name: "12" },
        { id: "13", name: "13" },
        { id: "14", name: "14" },
        { id: "15", name: "15" },
        { id: "16", name: "16" },
        { id: "17", name: "17" },
        { id: "18", name: "18" },
        { id: "19", name: "19" },
        { id: "20", name: "20" },
      ],
      formSubmitted: false,
      assistants: [],
      invService: {},
    };
  },
  created() {
    this.getInvoices();
    this.getConnectPatient();
    this.getServices();
    this.memebershipTypes();
  },
  computed: {
    ...mapGetters("auth", ["userInstitute", "authUser"]),
    subtotal() {
      if (this.service_invoice.appointment_id) {
        return this.service_details.reduce(
          (sum, item) => sum + item.payable_amount,
          0
        );
      }
      return this.service_details.reduce(
        (sum, item) => parseFloat(sum) + parseFloat(item.price),
        0
      );
    },
    total() {
      return this.getDiscount(
        this.subtotal,
        this.service_invoice.discount ? this.service_invoice.discount : 0
      );
    },
    remainingDue() {
      return Math.round(
        this.installment.total_due - this.installment.total_paid,
        2
      );
    },
  },
  methods: {
    createNewUser() {
      if(this.service_invoice.membership_type_id == 'Doctor Referral') {
        const routeData = this.$router.resolve({path: '/outside/doctors', query: {create: "yea"}});
        window.open(routeData.href, '_blank');
      } else {
        const routeData = this.$router.resolve({path: '/brokers', query: {create: "yea"}});
        window.open(routeData.href, '_blank');
      }
    },
    insCheckMinMaxVal(min, max, val) {
      if (val > max) {
        this.installment.total_paid = max;
      } else if (val < min) {
        this.installment.total_paid = min;
      }
    },
    checkItemValue(min, max, itemObj, field) {
      if (itemObj[field] > max) {
        itemObj[field] = max;
      } else if (itemObj[field] < min) {
        itemObj[field] = min;
      }
    },
    calculatePaidAmount() {
      this.installment.appointments = [];
      this.installment.total_paid = Math.ceil(
        (this.installment.invoice_total / this.installment.total_session) *
          this.installment.no_of_session_payment
      );
      for (let i = 0; i < this.installment.no_of_session_payment; i++) {
        this.installment.appointments.push({
          schedule_date: "",
          schedule_started_at: "",
          schedule_end_at: "",
          workplace_id: "",
          working_schedule_time_slot: "",
          chambers: [],
        });
      }
    },
    showServiceDialog(invoice) {
      this.invService.invoice_id = invoice.id;
      this.invService.product_type = invoice.product_type;
      this.invServiceDialog = true;
    },
    showInstallmentDialog(invoice) {
      this.installment = {};
      this.installment.invoice_id = invoice.id;
      this.installment.total_paid = 0;
      this.installment.total_due = invoice.total_due;
      this.installment.invoice_total = invoice.total;

      if (invoice.orko_therapy_package_id && invoice.no_of_session_payment) {
        this.installment.invoice_no_of_session_payment =
          invoice.no_of_session_payment;
        this.installment.service_type = "visit";
        this.installment.slot_value = "";
        this.getPhysioAssistants();

        axios.get(`/api/v2/invoices/${invoice.uid}`).then((res) => {
          if (res.data.status_code == 500) {
            this.$notify({
              title: "Failed",
              message: res.data.message,
              type: "danger",
              duration: 2000,
            });
          }

          this.installment.total_session =
            res.data.data.orko_therapy_package.no_of_sessions;
          this.installmentDialog = true;
        });
      } else {
        this.installmentDialog = true;
      }
    },
    formDataNull() {
      this.installment.slot_value = "";
    },
    disabledDate(time) {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      return time.getTime() < date;
    },
    clickDate(date_value, index) {
      this.installment.appointments[index].chambers = [];
      this.loading = true;
      const url = `/api/v1/doctor/chamber/list?schedule_date=${date_value}&doctor_id=${
        this.installment.doctor_id
      }${this.installment.service_type == "visit" ? "" : "&type=telemedicine"}`;
      axios.get(url).then((res) => {
        this.installment.appointments[index].chambers = res.data.data;
        this.loading = false;
      });
    },
    getPhysioAssistants() {
      this.loading = true;
      axios
        .get(
          "/api/v2/doctor/center-wise/list?sub_type=physio_assistant&appointment=yes"
        )
        .then((response) => {
          this.assistants = response.data.data;
          this.loading = false;
        });
    },
    submitSerInvoice() {
      this.loading = true;
      axios
        .post(
          `/api/v2/invoices/${this.invService.invoice_id}/services`,
          this.invService
        )
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$notify({
              title: "Success",
              message: "Service Successfully Added",
              type: "success",
              duration: 3000,
            });
            this.invService = {};
            this.invServiceDialog = false;
            // this.$router.push({
            //   path: '/therapy/invoice',
            //   query: { id: res.data.data.uid },
            // });
          } else {
            this.$notify({
              title: "Failed",
              message: res.data.message,
              type: "danger",
              duration: 3000,
            });
          }

          this.loading = false;
        });
    },
    submitInstallment() {
      if (!this.installment.total_paid) {
        this.$notify({
          title: "Error",
          message: "Invalid installment amount.",
          type: "error",
          duration: 3000,
        });
        return false;
      }
      this.loading = true;

      if (this.installment.total_session) {
        this.installment.appointments = this.installment.appointments.map(
          (item) => {
            item.schedule_end_at = this.addMinutes(
              item.schedule_started_at,
              item.working_schedule_time_slot
            );
            item.chambers = [];
            return item;
          }
        );
      }

      axios
        .post(
          `/api/v2/invoices/${this.installment.invoice_id}/installments`,
          this.installment
        )
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$notify({
              title: "Success",
              message: "Installment Successfully Added",
              type: "success",
              duration: 3000,
            });
            this.installment = {};
            this.$router.push({
              path: "/therapy/invoice",
              query: { id: res.data.data.uid },
            });
          } else {
            this.$notify({
              title: "Failed",
              message: res.data.message,
              type: "danger",
              duration: 3000,
            });
          }

          this.loading = false;
        });
    },
    addMinutes(time, minsToAdd) {
      function D(J) {
        return (J < 10 ? "0" : "") + J;
      }
      const piece = time.split(":");
      const mins = piece[0] * 60 + +piece[1] + +minsToAdd;

      return `${D(((mins % (24 * 60)) / 60) | 0)}:${D(mins % 60)}`;
    },
    workPlaceId(workplace, index) {
      this.installment.appointments[index].workplace_id = workplace.id;
      this.installment.appointments[index].working_schedule_time_slot =
        workplace.working_schedules[0].time_per_slot;
    },
    setServicePrice(service) {
      let serviceObj = this.subServices.find((item) => item.id == service.id);
      if (serviceObj) {
        service.unit_price = serviceObj.price;
        let price =
          serviceObj.price -
          Math.round(serviceObj.price * (service.discount / 100));
        let minPrice =
          serviceObj.min_price -
          Math.round(serviceObj.min_price * (service.discount / 100));
        service.price = price * service.quantity;
        service.min_price = minPrice * service.quantity;
        service.max_price = price * service.quantity;
        service.name = serviceObj.name;
        if (serviceObj.product_id) {
          service.product_id = serviceObj.product_id;
          service.available_quantity = serviceObj.available_quantity;
        }
      }
    },
    updateUnitPrice(item) {
      item.unit_price = Math.round(item.price / item.quantity);
    },
    changePatient() {
      this.service_invoice.service_id = "";
      this.service_details = [];
    },
    exportToExcel() {
      axios
        .get(
          `/api/v2/invoices/export?${
            this.start_date ? `&start_date=${this.start_date}` : ""
          }${this.end_date ? `&end_date=${this.end_date}` : ""}${
            this.phone_number ? `&phone_number=${this.phone_number}` : ""
          }${this.center_id ? `&institute_id=${this.center_id}` : ""}
          ${this.membership_type_id ? `&membership_type_id=${this.membership_type_id}` : ""}
          ${this.platform ? `&platform=${this.platform}` : ""}${
            this.is_zakat ? "&is_zakat=1" : "&is_zakat=0"
          }&orderBy=${this.orderBy}`,
          { responseType: "blob" }
        )
        .then((response) => {
          const filename = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(".")[0];
          const extension = response.headers["content-disposition"]
            .split(".")[1]
            .split(";")[0];
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}.${extension}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },

    getInvoices(val) {
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }

      this.loading = true;

      axios
        .get(
          `/api/v2/invoices?limit=${this.per_page}&offset=${correctValue || 0}${
            this.start_date ? `&start_date=${this.start_date}` : ""
          }${this.end_date ? `&end_date=${this.end_date}` : ""}${
            this.phone_number ? `&phone_number=${this.phone_number}` : ""
          }${this.center_id ? `&institute_id=${this.center_id}` : ""}
          ${this.membership_type_id ? `&membership_type_id=${this.membership_type_id}` : ""}
          ${this.platform ? `&platform=${this.platform}` : ""}${
            this.is_zakat ? "&is_zakat=1" : "&is_zakat=0"
          }&orderBy=${this.orderBy}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.total_count = res.data.total_count;
          this.loading = false;
        });
    },

    sortData(sort) {
      if (sort.order == "ascending") {
        this.orderBy = "date_asc";
      } else {
        this.orderBy = "date_desc";
      }
      this.getInvoices();
    },

    dateChanged() {
      if (this.service_invoice.service_id == "Appointment") {
        this.appointment_id = null;
        this.service_details = [];
        this.priceCalculate();
      }
    },
    getConnectPatient() {
      const url = "/api/v2/partner/agent/patients";
      axios.get(url).then((response) => {
        this.patients = response.data.data;
      });
    },
    getServices() {
      axios.get("/api/v2/orko-services?platform=occ").then((res) => {
        this.services = res.data.data;
        this.services.push({
          group_name: "appointment",
          name: "Appointment",
        });
        if (res.data.status_code != 200) {
          this.$notify({
            title: "Failed",
            message: res.data.message,
            type: "error",
            duration: 2000,
          });
        }
      });
    },
    getSubServices(group) {
      this.loading = true;
      axios.get(`/api/v2/orko-services?group_name=${group}`).then((res) => {
        if (res.data.status_code != 200) {
          this.loading = false;
          return this.$notify({
            title: "Failed",
            message: res.data.message,
            type: "error",
            duration: 2000,
          });
        }
        this.service_details.push({
          id: "",
          unit_price: "",
          price: "",
          name: "",
          discount: 0,
          description: "",
          service_type: "",
          quantity: 1,
        });
        this.subServices = res.data.data;
        this.loading = false;
      });
    },
    addNewRow() {
      this.service_details.push({
        id: "",
        unit_price: "",
        price: "",
        name: "",
        description: "",
        service_type: "",
        discount: 0,
        quantity: 1,
      });
    },
    removeLastRow() {
      this.service_details.splice(-1);
    },
    priceCalculate() {
      if (!this.service_invoice.patient_id) {
        this.$notify({
          title: "Failed",
          message: "Patient is not selected!",
          type: "error",
          duration: 3000,
        });
        this.service_invoice.service_id = "";
        return false;
      }
      this.subServices = [];
      this.service_invoice.appointment_id = "";
      this.service_details = [];

      const service_obj = this.services.find(
        (item) => item.name == this.service_invoice.service_id
      );

      // if(service_obj.price) {
      //   this.service_details.push(
      //     {
      //       id: service_obj.id,
      //       name: service_obj.name,
      //       price: service_obj.price,
      //       description: 'SKU - '+service_obj.sku+', Size - '+ service_obj.size,
      //       quantity:1,
      //       product_id: service_obj.product_id,
      //   }
      //   );
      //   this.service_invoice.product_type = service_obj.name;
      //   this.service_invoice.product_id = service_obj.product_id;
      //   return true;
      // }

      // if(service_obj.group_name == 'home_service') {
      //   this.service_invoice.product_type = service_obj.name;
      //   service_obj.quantity = 1;
      //   service_obj.price = 0;
      //   this.service_details.push(service_obj);
      //   return false;
      // }
      if (
        ![
          "registration_fee",
          "brace",
          "ponseti",
          "appointment",
          "home_service",
        ].includes(service_obj.group_name)
      ) {
        this.service_invoice.product_type = service_obj.name;
        this.getSubServices(service_obj.group_name);
        if (service_obj.group_name == "dental") {
          this.getDcotors();
        }
        return false;
      }
      this.loading = true;

      if (this.service_invoice.service_id == "Appointment") {
        const patient_obj = this.patients.find(
          (item) => item.id == this.service_invoice.patient_id
        );
        const url = `/api/v2/appointment/list/new?search_type=patient&status=served&user_type=agent&for_agent=1${
          this.service_invoice.invoice_date
            ? `&schedule_date=${this.service_invoice.invoice_date}`
            : ""
        }&q=${patient_obj.phone_number}`;
        axios.get(url).then((res) => {
          this.appointments = res.data.data;
          this.appointments = this.appointments.filter(
            (item) => item.appointment_status != "canceled"
          );
          this.loading = false;
        });
      } else {
        axios
          .get(
            `/api/v2/orko-services/price/calculate?service_name=${
              this.service_invoice.service_id
            }
            &patient_id=${this.service_invoice.patient_id}
            ${
              this.service_invoice.no_of_foot
                ? "&no_of_foot=" + this.service_invoice.no_of_foot
                : ""
            }`
          )
          .then((res) => {
            if (res.data.status_code != 200) {
              this.loading = false;
              return this.$notify({
                title: "Failed",
                message: res.data.message,
                type: "error",
                duration: 3000,
              });
            }

            let newServicePrice = res.data.data;
            newServicePrice.quantity = 1;
            this.service_details.push(newServicePrice);

            // const service_obj = this.services.find(
            //   (item) => item.name == this.service_invoice.service_id,
            // );
            this.service_invoice.product_type = service_obj.name;
            this.loading = false;
          });
      }
    },
    selectAppointment() {
      const appoitmentObj = this.appointments.find(
        (item) => item.id == this.service_invoice.appointment_id
      );
      this.service_details = [];
      this.service_details.push(appoitmentObj);
    },
    searchGlobalPatient(value) {
      if (value) {
        axios
          .get(`/api/v1/patient/subordinate/list?term=${value}`)
          .then((response) => {
            this.patients = response.data.data;
          });
      }
    },
    getDiscount(total, discountPercent) {
      const discountAmount = total * (discountPercent / 100);
      if (this.welfare_details.discount_due) {
        return (
          total -
          (discountAmount + parseInt(this.service_invoice.welfare_discount))
        );
      } else {
        return total - discountAmount;
      }
    },
    openServiceInvoiceModal() {
      this.service_invoice_dailog = true;
    },
    previewInvoice(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          if (!this.service_invoice.total_paid) {
            this.service_invoice.total_paid = 0;
          }
          this.is_preview = true;
        } else {
          return true;
        }
      });
    },
    submitInvoice() {
      if (this.formSubmitted) {
        return false; //disable multiple click at a time
      }
      this.formSubmitted = true;
      const items = [];
      if (this.service_details && this.service_invoice.appointment_id) {
        this.service_details.map((item) => {
          items.push({
            id: item.id,
            quantity: item.quantity ? item.quantity : 1,
            unit_price: item.payable_amount,
            total: item.payable_amount,
            discount: item.discount,
          });
        });
      } else {
        this.service_details.map((item) => {
          items.push({
            id: item.id,
            quantity: item.quantity ? item.quantity : 1,
            unit_price: item.unit_price ? item.unit_price : item.price,
            total: item.price,
            discount: item.discount,
            product_id: item.product_id ? item.product_id : "",
          });
        });
      }

      if (this.service_invoice.product_type == "Brace") {
        this.service_invoice.product_type = "brace";
      } else if (this.service_invoice.product_type == "Registration Fee") {
        this.service_invoice.product_type = "registration_fee";
        this.service_invoice.center_code = "CM";
      }

      const memberShipId = this.memberTypes.find(
        (item) => item.name == this.service_invoice.membership_type_id
      );
      this.invoice = {
        patient_id: this.service_invoice ? this.service_invoice.patient_id : "",
        subtotal: this.subtotal,
        service_type: this.service_invoice.service_type,
        discount_percentage: this.service_invoice.discount
          ? this.service_invoice.discount
          : 0,
        discount_reason: this.service_invoice.discount_reason,
        vat: "0",
        total: this.total,
        total_paid: this.service_invoice.total_paid,
        status: "paid",
        is_followup: this.service_invoice.is_followup,
        doctor_id: this.service_invoice.doctor_id,
        membership_type_id: memberShipId ? memberShipId.id : "",
        referred_by: this.service_invoice.employee_id
          ? this.service_invoice.employee_id
          : "",
        invoice_date: this.service_invoice.invoice_date,
        product_type: this.service_invoice.appointment_id
          ? "appointment"
          : this.service_invoice.product_type,
        comment: this.service_invoice.comment,
        discount: this.service_invoice.discount,
        center_code: this.userInstitute.short_form,
        welfare_application_id: this.welfare_details.id
          ? this.welfare_details.id
          : "",
        welfare_discount: this.welfare_details.id
          ? this.service_invoice.welfare_discount
          : 0,
        home_service_provided_by: this.service_invoice.home_service_provided_by,
        size: this.service_invoice.size,
        items,
      };

      if (this.service_invoice.appointment_id) {
        this.invoice.appointment_id = this.service_invoice.appointment_id;
      }

      if (this.service_invoice.no_of_foot) {
        this.invoice.no_of_foot = this.service_invoice.no_of_foot;
      }
      if (this.service_invoice.product_id) {
        this.invoice.product_id = this.service_invoice.product_id;
      }
      axios.post("/api/v2/invoices", this.invoice).then((res) => {
        if (res.data.status_code == 200) {
          this.$notify({
            title: "Success",
            message: "Successfully Generated",
            type: "success",
            duration: 2000,
          });
          this.generateInvoiceDialog = false;
          this.invoice = {};
          // this.formSubmitted = false;
          // this.invoice_details = {};
          this.$router.push({
            path: "/therapy/invoice",
            query: { id: res.data.data.uid },
          });
        } else {
          this.$notify({
            title: "Failed",
            message: res.data.message,
            type: "danger",
            duration: 2000,
          });
          this.formSubmitted = false;
        }
      });
    },
    removeService(index) {
      this.service_details.splice(index, 1);
    },
    todayDate() {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = today.getFullYear();
      today = `${yyyy}-${mm}-${dd}`;
      return today;
    },
    memebershipTypes() {
      axios.get("/api/v2/membership-types").then((res) => {
        this.memberTypes = res.data.data;
      });
    },
    clear() {
      this.start_date = null;
      this.end_date = null;
      this.getInvoices();
    },
    getEmployees(value) {
      let type = "";
      if (this.service_invoice.membership_type_id == "Doctor Referral") {
        type = "doctor";
      } else if (this.service_invoice.membership_type_id == "Corporate") {
        type = "corporate";
      } else if (
        this.service_invoice.membership_type_id == "Corporate Family"
      ) {
        type = "corporate-family";
      } else if (this.service_invoice.membership_type_id == "Employee Family") {
        type = "employee-family";
      } else if (this.service_invoice.membership_type_id == "General Referral") {
        type = "broker";
      } else {
        type = "employee";
      }

      axios
        .get(
          `/api/v2/search/members?membership_type=${
            this.service_invoice.membership_type_id
          }&type=${type}&q=${value || ""}`
        )
        .then((res) => {
          if (value) {
            this.employees = res.data.data;
          }
        });
    },
    checkMembershipType() {
      // this.service_invoice.employee_id
      const membershipType = this.memberTypes.find(
        (item) => item.name == this.service_invoice.membership_type_id
      );
      const selectedEmp = this.employees.find(
        (item) => item.id == this.service_invoice.employee_id
      );

      if (selectedEmp) {
        // this.service_invoice.discount = selectedEmp.discount;
        this.service_invoice.discount = 0;
        this.service_invoice.discount_reason = membershipType.name;
        // this.service_invoice.membership_type_id = discount.id;
      } else {
        this.service_invoice.discount = "";
      }
    },

    checkDoctorReferral() {
      axios
        .get(
          `/api/v2/check/doctor-referral?patient_id=${this.service_invoice.patient_id}`
        )
        .then((res) => {
          if (res.data.status_code == 200) {
            this.employees.push(res.data.data);
            this.service_invoice.employee_id = res.data.data.id;
            this.service_invoice.membership_type_id = "Doctor Referral";
            this.service_invoice.discount = res.data.data.discount;
            this.service_invoice.discount_reason = "Doctor Referral";
          }
        });
    },
    changeEmployeeType() {
      this.employees = [];
      this.service_invoice.employee_id = "";
      this.service_invoice.discount = "";
    },
    patientName(pateint) {
      const patientObj = this.patients.find((item) => item.id == pateint);
      return patientObj.fullname;
    },
    serviceName(serivce) {
      const serviceObj = this.services.find((item) => item.name == serivce);
      return serviceObj.name;
    },
    getServiceType(servicetype) {
      const serviceTypeObj = this.serviceType.find(
        (item) => item.label == servicetype
      );
      return serviceTypeObj.label;
    },
    membershipTypeNameById(name) {
      const membership = this.memberTypes.find((item) => item.name == name);
      return membership.name;
    },
    employeeNameById(id) {
      const employee = this.employees.find((item) => item.id == id);
      return employee.fullname;
    },
    appointmentById(id) {
      const appointment = this.appointments.find((item) => item.id == id);
      return `${appointment.schedule_date}(${appointment.schedule_started_at})`;
    },
    itemDiscount(discount = 0, price) {
      if (discount) {
        const discountAmount = price * (discount / 100);
        return price - discountAmount;
      }
      return price;
    },
    getDcotors() {
      this.loading = true;
      axios
        .get(
          `/api/v2/doctor/center-wise/list?sub_type=dentist${
            this.center_id ? `&institute_id=${this.center_id}` : ""
          }`
        )
        .then((response) => {
          this.doctors = response.data.data;
          this.loading = false;
        });
    },

    checkWelfareDiscount() {
      if (this.is_walefire_discount) {
        axios
          .get(
            `/api/v2/welfare/applications/${this.service_invoice.patient_id}/check`
          )
          .then((res) => {
            if (res.data.data) {
              ElMessage({
                message: "Yes, you got discount",
                type: "success",
              });
              this.welfare_details = res.data.data;
              this.service_invoice.welfare_discount =
                this.welfare_details.discount_due;
            } else {
              ElMessage({
                message: "Sorry, patient is not Eligible",
                type: "error",
              });
            }
          });
      } else {
        this.welfare_details = {};
      }
    },
  },
  watch: {
    service_invoice_dailog(oldValue, newVal) {
      if (!oldValue) {
        this.service_invoice = {
          invoice_date: this.todayDate(),
        };
        this.is_preview = false;

        this.service_details = [];
        this.welfare_details = {};
      }
    },
  },
};
</script>

<style>
.w-100 {
  width: 100% !important;
}
</style>
<style scoped>
.text-center {
  text-align: center !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th,
td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.text-right {
  text-align: right;
}
</style>
