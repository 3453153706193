<template>
  <div>
    <el-row justify="space-between" align="middle">
      <el-col :span="6"><h3 class="mb-0">Transaction Statement</h3></el-col>
    </el-row>
    <br>

    <el-row :gutter="20" align="middle">
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <p class="demonstration">Start date</p>
        <el-date-picker
            v-model="start_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <p class="demonstration">End date</p>
        <el-date-picker
            v-model="end_date"
            type="date"
            class="w-100"
            placeholder="Pick a day"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="large"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="mt-40">
        <el-button type="primary" size="large" @click="getStatements" round>Filter</el-button>
        <el-button type="danger" size="large" @click="clear" round>Clear</el-button>
      </el-col>
      <el-col :xs="4" :sm="4" :md="2" :lg="2" :xl="2" class="mt-40">
        <el-button type="warning" size="large" @click="getStatements" circle>
          <font-awesome-icon icon="fa-solid fa-rotate-right"/>
        </el-button>
      </el-col>
    </el-row>
    <br>

    <el-table :data="tableData" v-loading="loading" border style="width: 100%">
      <el-table-column prop="doctor_name" label="Doctors" width="200">
        <template #default="scope">
          <UserCard :name="scope.row.doctor_name" :image="scope.row.doctor_image"></UserCard>
        </template>
      </el-table-column>
      <el-table-column prop="patient_name" label="Patients" width="200">
        <template #default="scope">
          <UserCard :name="scope.row.patient_name" :phone_number="scope.row.patient_phone_number"
                    :image="scope.row.patient_image"></UserCard>
        </template>
      </el-table-column>
      <el-table-column prop="schedule_date" label="Booking date" width="160">
        <template #default="scope">
          <el-tag class="ml-2" type="success">{{ scope.row.schedule_started_at }}</el-tag>
          to
          <el-tag class="ml-2" type="danger">{{ scope.row.schedule_end_at }}</el-tag>

        </template>
      </el-table-column>
      <el-table-column prop="service_type" label="Service type"/>
      <el-table-column prop="payable_amount" label="Total amount"/>
    </el-table>
    <el-pagination
        v-model:current-page="currentPage"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="total_count"
        @current-change="getStatements"
    />
  </div>
</template>

<script>
import axios from 'axios';
import UserCard from '../../components/core/UserCard';

export default {
  name: 'TransactionDetails',
  components: {
    UserCard,
  },
  data() {
    return {
      tableData: [],
      search: '',
      total_count: 0,
      loading: false,
      currentPage: 0,
      per_page: 20,
      start_date: null,
      end_date: null,
    };
  },
  created() {
    this.getStatements();
  },
  methods: {
    getStatements(val) {
      this.loading = true;
      if (val) {
        const offset = val - 1;
        var correctValue = offset * this.per_page;
      }
      axios.get(`/api/v2/payment/occ-transactions?limit=${this.per_page}&offset=${correctValue || 0}${this.start_date ? `&start_date=${this.start_date}` : ''}${this.end_date ? `&end_date=${this.end_date}` : ''}`)
        .then((response) => {
          this.tableData = response.data.data;
          this.total_count = response.data.data.total_count;
          this.loading = false;
        });
    },
    clear() {
      this.start_date = null;
      this.end_date = null;
      this.getStatements();
    },
  },
  title: 'Transaction statement',
};
</script>
<style>
.w-100 {
  width: 100% !important;
}
</style>
